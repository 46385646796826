import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Schedule",
    component: Home,
    props:true
  },
  {
    path: "/reports",
    name: "reports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/reports.vue"),
    props: true
  },
  {
    path: "/fullReports",
    name: "fullReports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/fullReports.vue"),
    props: true
  },
  {
    path: "/extendedReports/:code",
    name: "extendedReports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/extendedReports.vue"),
    props: true
  },
  {
    path: "/wpgextendedReports/:code",
    name: "wpgextendedReports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/wpgextendedReports.vue"),
    props: true
  },
  {
    path: "/womensextendedReports/:code",
    name: "womensextendedReports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/womensextendedReports.vue"),
    props: true
  },
  {
    path: "/schedule",
    name: "schedule",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/schedule.vue"),
    props: true
  },
  {
  path: "/live",
  name: "live",
  component: () =>
    import(/* webpackChunkName: "about" */ "../views/live.vue"),
  },
  {
    path: "/playerprofile/:refno",
    name: "playerprofile",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/playerprofile.vue"),
    props: true
  },
  {
    path: "/news",
    name: "News",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/news.vue")
  },
  {
    path: "/pga-pro",
    name: "pgapro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/pgaPro.vue")
  },
  {
    path: "/news/:id",
    name: "news story",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/newsStory.vue"),
    props: true
  },
  {
    path: "/video",
    name: "Video",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/video.vue")
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
      props:true
  },
  {
    path: "/adstest",
    name: "adstest",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/adstest.vue"),
      props:true
  },
  {
    path: "/about/:slug",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
      props:true
  },
  {
    path: "/qschool",
    name: "qschool",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue")
  },
  {
    path: "/tournament",
    name: "tournament",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/tournament.vue"),
    props: true
  },
  {
    path: "/oom",
    name: "oom",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/oom.vue"),
    props: true
  },
  {
    path: "/education",
    name: "education",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/education.vue"),
    props: true
  },
  { path: '/education/pga-academy', redirect: '/education/academy' },
  {
    path: "/education/:slug",
    name: "education",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/education.vue"),
    props: true
  },
  {
    path: "/play",
    name: "play",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/education.vue"),
    props: true
  },
  {
    path: "/play/:slug",
    name: "play",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/education.vue"),
    props: true
  },
  {
    path: "/findPro",
    name: "findPro",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/findPro.vue"),
    props: true
  },
  {
    path: "/findPro/:slug",
    name: "findPro",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/about.vue"),
      props:true
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/search.vue"),
    props: true
  },
  { 
    path: '/:catchAll(.*)', 
    name: 'NotFound'
  }
];

const router = new VueRouter({
  routes,
  mode:'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
});

export default router;
